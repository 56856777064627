.topbar-call ul li a {
  margin-right: 30px;
  color: #fff;
}
.topbar-call ul li i {
  margin-right: 5px;
  color: #ffffff;
  vertical-align: middle;
  font-size: 15px;
  background-color: #379CFFFC;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 8px;
}
.topbar ul li {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  font-weight: normal;
  margin-right: 20px;
  text-decoration:none;
}
.topbar ul li a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  text-decoration: none;

}
.topbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 5px 0;
}
.topbar-social {
  margin-top:15px;
  
  text-align: right;
}
@media only screen and (min-width: 375px) and (max-width: 667px){
  img.consult-img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
}
img.ai-image {
  width: 100% !important;
  height: auto !important;
  object-fit: cover !important;
}
img.app-image {
  width: 100% !important;
  height: auto !important;
  object-fit: cover !important;
}
  img.enterpriseimg.img-fluid {
     display: none; 
}
  .topbar ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
}
  .topbar-social {
    
    display: none;
}

.topbar-call {
  text-align: left;
}
}
p{
  color:#fff;
  font-size:15px;
}
h4{
  color:#fff;
  font-size: 22px;
}
h5{
  color:#fff;
}

/*
h3{
color: #379CFFFC;
          
          transform:rotate(-90deg);
  
}
*/
.card.data-card {
  border: 1px solid grey;
  background: transparent;
  border-radius: 20px;
  padding:30px;
  height: 290px;
}
.about-images{
  height:250px;
}
.about-images4{
  border-color: #379cff;
  box-shadow: #379cff 0px 0px 16px 0px;
  height: 250px;
  
}
.about-images2{
  border-color: #379cff;
  box-shadow: #379cff 0px 0px 16px 0px;
  height: 250px;
  float: right;
}
.about-images3{
  border-color: #379cff;
  box-shadow: #379cff 0px 0px 16px 0px;
  height: 350px;
}
button.btn.btn-primary {
 
  background-color: #72D3FF00;
  font-size: 14px;
  box-shadow: 0px 0px 12px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471) inset;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #379CFFFC;
  border-radius: 50px 50px 50px 50px;

}
ul.mt-3.jobopen {
  display: flex;
  list-style: none;
}
.symbol{
  color:#379CFFFC;
}
.fa-arrow {
color:379CFFFC;
}
h6{
  color:#fff;
  font-size:22px;
}
/***********************************header*********************************************************/
.head-a{
  text-align: left;
                width: var(--container-widget-width, 18px );
                max-width: 18px;
                --container-widget-width: 18px;
                --container-widget-flex-grow: 0;
                align-self: flex-end;
}
.head-b{
  margin-left:60px;
  margin-top: 50px;
                   --e-transform-rotateZ: -90deg;
                   transform: perspective(var(--e-transform-perspective,0)) rotate(var(--e-transform-rotateZ,0)) rotateX(var(--e-transform-rotateX,0)) rotateY(var(--e-transform-rotateY,0)) translate(var(--e-transform-translate,0)) translateX(var(--e-transform-translateX,0)) translateY(var(--e-transform-translateY,0)) scaleX(calc(var(--e-transform-flipX, 1) * var(--e-transform-scaleX, var(--e-transform-scale, 1)))) scaleY(calc(var(--e-transform-flipY, 1) * var(--e-transform-scaleY, var(--e-transform-scale, 1)))) skewX(var(--e-transform-skewX,0)) skewY(var(--e-transform-skewY,0));
                   transform-origin: var(--e-transform-origin-y) var(--e-transform-origin-x);
}
.head2b{
  padding: 0;
                      margin: 0;
                      display: inline-block;
                      font-size: 30px;

                      
}
.headabout{
  padding: 50px;
  margin: 0;
  display: inline-block;
  text-align: center;      
  
 
                      
}
.head3b{
  padding: 30px;
                      margin: 0;
                      display: inline-block;
                      margin-left:44px;
                     font-size: 50px;
                      
}
span.dynamic-wrapper.style-gradient {
  overflow: visible;
text-align: inherit;
display: inline-block;
position: relative;
vertical-align: bottom;
}
.dynamic-text{
  background-color: transparent;background: linear-gradient(180deg,var(--jkit-element-lighten-bg-color) 0,var(--jkit-element-darken-bg-color) 100%);
                         -moz-text-fill-color: transparent;
                         -o-text-fill-color: transparent;
                         -webkit-text-fill-color: transparent;
                         background-clip: text;
                         -webkit-background-clip: text;
                         background-image: linear-gradient(60deg, #FFFFFF 60%, #3D9EEBFC 100%);
}
.span.normal-text.style-gradient {
  background: linear-gradient(180deg,var(--jkit-element-lighten-bg-color) 0,var(--jkit-element-darken-bg-color) 100%);
                         -moz-text-fill-color: transparent;
                         -o-text-fill-color: transparent;
                         -webkit-text-fill-color: transparent;
                         background-clip: text;
                         -webkit-background-clip: text;
}
/****************************************header end************************************************/


.portfolio-img{
  width:100%;
  height: 320px;
  object-fit: cover;
  display: block;
}
.profile-card{
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #379CFF;
  box-shadow: 0px 0px 16px 0px #379CFF;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.read{
  color:#379CFF;
}
.profile-title{
  color:#379CFFFC;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}


.accordion-body{
  background-color:#020510;
  border: 0px;
}
.team1{
  height:130px;
  width:130px;
  border:3px solid #379CFF;
  border-radius: 50%;
}
.tech-lead{
  color:#379CFF;
}
.list-f{
  margin-right: 20px;
  color: #fff;
  text-decoration: none;
}
.list-f2 {
  margin-right: 30px;
  font-size: 12px;
  color: #379CFF;
}
.list-f2 a {
  text-decoration: none;
  color: #379CFF;
}
.Copyright{
  font-size: 12px;
  color: #379CFF;
}
.footer-list{
  list-style: none;display: flex;color:#fff;
}
/*
@media only screen and (min-width: 375px) and (max-width: 667px){
  .dynamic-text {
    background-color: initial;
    background: linear-gradient(180deg, var(--jkit-element-lighten-bg-color) 0, var(--jkit-element-darken-bg-color) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(60deg, #fff 60%, #3d9eebfc);
    font-size: 14px;
    margin-left: 45px !important;
    float: right !important;
    text-align: left !important;
}*/
@media only screen and (min-width: 375px) and (max-width: 667px){
  ul.mt-3.jobopen {
  display: inline;
    list-style: none;
  }
  .dynamic-text {
    background-color: initial;
    background: linear-gradient(180deg, var(--jkit-element-lighten-bg-color) 0, var(--jkit-element-darken-bg-color) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(60deg, #fff 60%, #3d9eebfc);
    font-size: 20px;
    text-align: center !important;
    
}
h5.elementor-heading-title.elementor-size-default {
  display: none;
}
span.dynamic-wrapper.style-gradient {
  overflow: visible;
  text-align: inherit;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  
}
.footer-list {
    color: #fff;
    /* display: inline-block!important;  */
    font-size: 10px;
    list-style: none;
}}
@media only screen and (min-width: 350px) and (max-width: 740px){
  .footer-list {
      color: #fff;
      
      font-size: 7px;
      list-style: none;
  }}
  @media only screen and (min-width: 280px) and (max-width: 653px){
    .footer-list {
        color: #fff;
        
        font-size: 5px;
        list-style: none;
    }}
    @media only screen and (min-width: 280px) and (max-width: 653px){
      .about-images {
        height: 157px;
    }
   
  }
  @media only screen and (min-width: 280px) and (max-width: 653px) {
    p {
      font-size: 13px !important;
    }
  }
  @media only screen and (min-width: 820px) and (max-width: 1180px) {
    p {
      font-size: 13px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1180px) {
    p {
      font-size: 13px !important;
    }
  }
  
  @media only screen and (min-width: 280px) and (max-width: 653px){
    .signin-logo {
      height: 51px !important;
  }}
 
  @media only screen and (min-width: 280px) and (max-width: 653px){
    .contactimage{
      height: 170px !important;
       width: 200px !important; 
       border-radius:10px;
     
      }
 
}
@media only screen and (min-width: 280px) and (max-width: 653px){
  .feedback{
    margin-top: 310px !important;
  }

}
@media only screen and (min-width: 820px) and (max-width: 1180px){
  .feedback{
    margin-top: 280px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  .feedback{
    margin-top: 280px !important;
  }
}
@media only screen and (min-width: 540px) and (max-width: 720px){
  .feedback{
    margin-top: 245px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px){
  .feedback{
    margin-top: 230px !important;
  }
}
.feedback{
  margin-top: 200px;
}

 .contactimage{
 height: 210px;
  width: 300px; 
  border-radius:10px;
 }
 .contactimage2{
  height: 244px;
  width: 440px;
  border-radius: 10px;
  float: right;
  

 }
.footer-list2{
  list-style: none;display: flex;color:#fff;
  float: right;
}
.social{
  color:#379CFF;
}
.social-links{
  list-style: none;
  display: flex;
  float:right;
}
/*logo*/
.signin-logo{
  height: 50px;
}
img.img-fluid.c-img {
  width:120px;
  }

  a.nav-link.scrollto.navhome {

  white-space: nowrap;
    padding: 10px 0 10px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-size: 15px;
    font-weight: 600;
    color: white;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    text-decoration: none;
    background-color:#020510;
}

a.nav-link.scrollto.navhome:hover{
 
  border-bottom: 3px solid #106eea;
}

a.nav-link.scrollto.active.navhome {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-size: 15px;
    font-weight: 600;
    color: white;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    text-decoration: none;
}

.accordion-flush .accordion-item .accordion-button {
  border: 1px solid #379cff;
  background-color:#020510;
  color:#fff;
}




/*** Service ***/
.service-item {
  position: relative;
  margin: 65px 0 25px 0;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
  height:280px;

}

.service-item .service-img {
  position: absolute;
  padding: 12px;
  width: 130px;
  height: 130px;
  top: -65px;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .09);
  z-index: 2;
}

.service-item .service-detail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.service-item .service-title {
  position: absolute;
  padding: 65px 30px 25px 30px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background:#090C1F;
  transition: .5s;
    border-style: solid;
    border-width: 1px;
    border-color: #379CFFFC;
   
   box-shadow:
      0px 0px 10px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471);
 object-fit: cover; 
 overflow: hidden;
}

.service-item:hover .service-title {
  top: -100%;
}

.service-item .service-text {
  position: absolute;
  overflow: hidden;
  padding: 65px 30px 25px 30px;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  display: flex;
  align-items: center;
  text-align: center;
  background: rgba(2, 36, 91, .7);
  transition: .5s;
}

.service-item:hover .service-text {
  top: 0;
}

.service-item .service-text::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  top: -100%;
  left: 0;
  transform: skewY(-12deg);
  background: #FFFFFF;
  transition: .5s;
}

.service-item:hover .service-text::before {
  top: -55px;
}

.service-item .btn {
  position: absolute;
  width: 130px;
  height: 50px;
  left: 50%;
  bottom: -25px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  background: #FFFFFF;
  border: none;
  box-shadow: 0 0 45px rgba(0, 0, 0, .09);
  z-index: 2;
}

.service-item .btn:hover {
  color: #FFFFFF;
  background: var(--primary);
}



.title1{
  font-size: 13px;
  text-decoration: none;
  color: #a3a2a2;
}
.title2{
font-size: 13px;
color: #2e2d2d;
}

.link-agreements {
  padding: 0px;
  margin: 0px;
  width: 95% !important; 
}

.link-agreements ul li {
  max-width:20%;
}

.link-agreements ul li a {
  height: 100%;
  max-height: 100%;
  border-radius: 10px 10px 0px 0px;
}

.tab-content {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid transparent;
  padding: 10px;
}

.tab-pane {
  margin: 0;
  padding: 0;
}

.htmltooltip table th {
  text-align: right;
  vertical-align: top;
  padding: 2px;
}

.htmltooltip table td {
  text-align: left;
  vertical-align: top;
  padding: 2px;
}

.htmltooltip {
  position: absolute;
  background: lightyellow;
  width: 25em;  
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-left: -2.5em;
  margin-top: 0.5em;
  padding: 5px;
  z-index: 2;
  display: none;
}

/*li:hover { background-color: lime; }*/

li:hover .htmltooltip
{
  display: block;
}

li.active:hover .htmltooltip
{
  display: none;
}

@media (min-width: 992px){
.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    margin: 10px 0px 0px 0px;
    padding: 10px 10px 10px 10px;
    border-style: none;
    background-color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    min-width: 220px;
    border-left: 7px solid #379CFFFC;
}
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
  height: 13px;
  width: 13px;
  border-radius: '50%';
  background-color: white;

}
